import { useContext } from "react";

import SessionContext from "store/session-context";

import { useClarityTracking } from "./useClarityTracking";

export const useAuthProtectedRoute = () => {
  const sessionCtx = useContext(SessionContext);

  const isAuthenticated = sessionCtx?.isLoggedIn;

  useClarityTracking(isAuthenticated, sessionCtx);

  return {
    isAuthenticated,
  };
};
