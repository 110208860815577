import Clarity from "@microsoft/clarity";
import { useEffect } from "react";

export function useClarityTracking(isAuthenticated, sessionCtx) {
  useEffect(() => {
    if (!isAuthenticated || !window?.clarity) return;

    const { user } = sessionCtx ?? {};

    if (!user?.email) return;

    const fullName = `${user.firstname ?? ""} ${user.lastname ?? ""}`.trim();

    const [splittedEmail] = user.email.split("@");

    if (splittedEmail.length > 0) {
      const identifier = splittedEmail.toLowerCase();
      Clarity.identify(identifier, identifier, "", fullName);
    }
  }, [isAuthenticated, sessionCtx]);
}
